import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter08 from '../common/src/assets/image/charity/specter/kapitola_08_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
        <SEO title="Ôsma kapitola: Ako komunizmus zasieva chaos v politike | Ako duch komunizmu vládne nášmu svetu"
             description="Ôsma kapitola: Ako komunizmus zasieva chaos v politike"
             image={imageChapter08}/>
        <h1>Ôsma kapitola: Ako komunizmus zasieva chaos v politike</h1>
        <h2>Úvod</h2>
        <p>Komunistická ideológia nezmizla z dejín s koncom studenej vojny. Pred a po páde komunistických režimov vo východnej Európe sa cez rozvracanie rozšírili marxistické myšlienky do celého slobodného sveta a mnohých demokraticky zvolených vlád sa zmocnili ľavicové hnutia.</p>
        <p>Na povrchu to vyzerá, že slobodný svet chápe, aké škody môže komunizmus napáchať, napriek tomu však za 170 rokov od doby, kedy bol vydaný Komunistický manifest, boli vlády na celom svete otvorene i skryto ovplyvňované marxistickou teóriou.</p>
        <p>Väčšina ľudí spája komunistickú politiku iba s krajinami, kde vládnu komunistické strany, alebo v ktorých sa otvorene dodržiavajú marxistické ekonomické doktríny. V skutočnosti však západná ľavica sleduje rovnakú základnú filozofiu boja, ktorú stelesňujú „tradičné“ komunistické režimy východu. V niektorých ohľadoch slobodný svet dokonca prekonal sebavedomé komunistické štáty, pokiaľ ide o uvádzanie ľavicových záležitostí do praxe.</p>
        <p>Popri tom ako Amerika počas studenej vojny bojovala proti sovietskej hrozbe, si formy komunizmu našli svoju cestu do takmer všetkých aspektov západnej spoločnosti pod pláštikom liberalizmu, progresivizmu a socializmu. Ľavicové krídlo má silné postavenie v politickom prostredí USA a je dominantné v mnohých európskych krajinách. Hoci sa duchovi komunizmu nepodarilo priviesť západný svet pod svoju priamu kontrolu, podmanil si vlády v západných štátoch presadzovaním socialistickej politiky, podnecovaním násilia, podkopávaním tradičnej morálky a vyvolávaním sociálnych nepokojov. Jeho cieľom je priviesť Západ na démonickú cestu, ktorá spôsobí zničenie ľudstva.</p>
        <p>Spojené štáty americké boli a zostávajú lídrom slobodného sveta a tradičnou baštou antikomunizmu. Vzhľadom na životne dôležitú úlohu, ktorú Amerika zohráva na svetovej scéne, je nevyhnutné, aby sme venovali osobitnú pozornosť vplyvu komunizmu na americkú politiku a vládu.</p>
        <h2>1. Komunizmus: politika na zničenie ľudstva</h2>
        <p>Po tisíce rokov bola hlavnou inštitúciou politickej moci monarchia, ktorá získala svoju moc od Boha. Nebesia obdarili vládcu božským právom kráľov. Cisári a králi zohrávali posvätnú úlohu ako prostredníci medzi človekom a božstvom.</p>
        <p>Dnes je veľa národov riadených demokraciami. Demokracia nie je v praxi priamou vládou ľudí, ale skôr vládou zástupcov zvolených ľuďmi. Voľba prezidenta je demokratickým postupom. Po nástupe do funkcie má prezident rozsiahle právomoci v oblasti politiky, ekonomiky, armády, zahraničných vzťahov atď.</p>
        <p>Od vyhlásenia nezávislosti v roku 1776 a vypracovania ústavy USA v nasledujúcom desaťročí bola demokracia spojená so slobodou, prosperitou a právami jednotlivca. Avšak základným kameňom sociálnej stability a harmónie, ako aj ľudských práv a&nbsp;slobody, sú morálne hodnoty spoločnosti. Demokracia sama osebe nemôže zaručiť, že budú zvolení dobrí ľudia. Keď klesá celková morálna úroveň spoločnosti, víťaznými kandidátmi môžu byť práve tí, ktorí sa špecializujú na prázdnu alebo zapálenú rétoriku alebo sú náchylní k rodinkárstvu. Pokiaľ demokracia neprijíma opatrenia na zachovanie morálnych štandardov stanovených božstvom, spôsobí to demokratickej spoločnosti veľké škody. Výhody volených zástupcov zmiznú a demokracia sa stane súčasťou „davovej“ politiky, ktorá vrhá spoločnosť do chaosu a roztrieštenosti.</p>
        <h3>a) Zbližovanie politiky a&nbsp;náboženstva v&nbsp;komunistických režimoch</h3>
        <p>Komunistická ideológia funguje ako ideológia v sekte. Núti svojich stúpencov, aby prijali zhubnú filozofiu boja, podrobili sa jej politickým programom a zradili svoje svedomie pri vykonávaní smerníc revolučného hnutia alebo strany. Komunistické režimy prenasledujú náboženstvo a spiritualitu najkrutejšími metódami, aby zničili božstvo a nahradili tradičnú vieru vlastným ateistickým náboženstvom.</p>
        <p>Komunistické režimy na východe, najmä režim ČKS, sa často mylne opisujú ako moderná forma starovekého despotizmu. Mnohí vidia ČKS ako pokračovanie cisárskej vlády, tradiční čínski panovníci však netvrdili, že definujú morálne hodnoty. Namiesto toho sa považovali za niekoho, kto sa chová v súlade s morálnymi štandardmi stanovenými bohmi či nebesami. ČKS si namiesto toho monopolizovala samotný koncept morálky. Bez ohľadu na to, akého zla sa ČKS dopúšťa, stále trvá na tom, že je „veľkolepá, slávna a&nbsp;správna“.</p>
        <p>Morálka je daná nebesami, nie človekom. Štandardy toho, čo je dobré a čo zlé, pramenia z Božích prikázaní, nie z ideologického obrazu niektorej politickej strany. Monopolizácia práva na definovanie morálky nevyhnutne vedie k prelínaniu cirkvi a štátu, čo sa prejavuje ako typické črty škodlivej sekty, ako to vidíme na prípade ČKS a iných komunistických strán.</p>
        <p>Komunistická strana uctieva Marxa ako svojho duchovného „Pána“ a považuje marxizmus za univerzálnu pravdu. Komunistický prísľub neba na zemi láka svojich stúpencov, aby za ňu položili svoje životy. Medzi jeho kultové rysy patrí okrem iného: vymýšľanie doktríny, likvidácia opozície, uctievanie vodcu, považovanie sa za jediný zdroj spravodlivosti, vymývania mozgu a kontrolu myslenia, vedenie zomknutej organizácie, ku ktorej sa môže človek pripojiť, ale nikdy ju nemôže opustiť, propagovanie násilia a túžby po krvi a povzbudzovanie k mučeníctvu z&nbsp;náboženských dôvodov.</p>
        <p>Všetci komunistickí vodcovia, ako napríklad Vladimír Lenin, Jozef Stalin, Mao Ce-tung a Kim Il Sung, mali svoje vlastné kulty osobnosti. Boli „pápežmi“ komunistického kultu vo svojich krajinách s nespornou autoritou rozhodovať o tom, čo je správne a čo nie. Či už zabíjali alebo klamali, vždy mali pravdu, čo bolo odôvodnené vysvetlením, že boli motivovaní vyšším cieľom alebo že zvažujú veci z dlhodobej perspektívy. Občania týchto krajín boli nútení opustiť svoje vlastné chápanie mravného dobra. Boli nútení klamať alebo dopúšťať sa zlých skutkov pod vedením strany, čo prinieslo ľuďom psychologické a duchovné traumy.</p>
        <p>Tradičné pravoverné náboženstvá učia ľudí, aby boli dobrí, ale kult komunizmu, postavený na nenávisti, má presne opačný prístup. Hoci strana hovorí aj o láske, „láska“, ktorú obhajuje, je založená na nenávisti. Napríklad proletári sú priateľskí voči príslušníkom svojej triedy, pretože čelia spoločnému nepriateľovi: kapitalistom. V modernej Číne sa možné vlastenectvo (v čínštine doslovne znamená „miluj zem“) prejavuje nenávisťou voči iným krajinám – nenávisťou voči Amerike, Francúzsku, Japonsku, Kórey, Taiwanu a nenávisťou voči zahraničným Číňanom, ktorí kritizujú ČKS.</p>
        <h3>b) Náboženský charakter liberalizmu a&nbsp;progresivizmu</h3>
        <p>Liberalizmus a progresivizmus sa dnes na Západe stali štandardom „politickej korektnosti“. V skutočnosti sa rozvinuli do tej miery, že sa z nich stalo svetské náboženstvo. Západní ľavičiari sa v histórii označovali rôznymi spôsobmi, niekedy sa nazývali „liberálnymi“ a niekedy sa nazývali „progresívnymi“. Zástancovia liberalizmu a progresivizmu obhajujú „pokrok“ ako absolútne morálne dobro a napádajú akýkoľvek nesúhlasný názor ako kacírstvo. Podobne ako komunizmus, ateizmus, evolúcia a veda, liberalizmus a progresivizmus nahrádzajú vieru v Boha humanistickým rozumom, čím sa vlastne človek pasuje za boha. Zdieľajú tých istých nepriateľov ako komunizmus a za sociálne problémy obviňujú nespravodlivosti alebo nedostatky v kapitalistickom systéme, ktorý majú v úmysle rozvrátiť alebo zvrhnúť. Metódy radikálnych liberálov a progresivistov sú podobné metódam komunistických revolucionárov. Považujú svoje dôvody za také dôležité, že na ich dosiahnutie neváhajú použiť akékoľvek prostriedky.</p>
        <p>Kvázi-náboženské charakteristiky liberalizmu a progresivizmu sú neoddeliteľné od teórií, ktoré im dali vznik. Rýchly vedecký pokrok od osemnásteho storočia výrazne posilnil dôveru ľudstva v jeho vlastné schopnosti a podporil progresívny intelektuálny trend. Francúzsky filozof Marquis de Condorcet, priekopník progresívneho myslenia, vo svojej práci <em>Skica pre historický obraz pokroku ľudskej mysle</em> uviedol, že rozum vedie ľudí na cestu šťastia a morálky alebo dobroty. Následne progresivizmus dostal agresívnejší náboj a&nbsp;spravil z rozumu predmet uctievania.</p>
        <p>Progresívne myslenie umožňuje človeku vnímať rozum, svedomie a Stvoriteľa ako oddelené entity, čo podporuje myšlienku, že ľudia spásu Stvoriteľa nepotrebujú a že človek môže použiť svoju vlastnú racionalitu a svedomie na odstránenie zla chamtivosti, strachu, žiarlivosti a podobne. Z tohto pohľadu môžu ľudia skoncovať s nebesami a vytvoriť si raj na zemi. Arogancia progresivizmu je vyjadrená vo vyhlásení francúzskeho politika a umeleckého kritika z 19. storočia Julesa-Antoina Castagnaryho: „Pri božskej záhrade, z ktorej som bol vyhnaný, postavím nový Eden. … Pri jeho vchode bude Pokrok ... a dám mu do ruky ohnivý meč a povie Bohu: ‚Sem nevstúpiš.‘“<a href="#_edn1" id="_ednref1">[1]</a></p>
        <p>Ľudia sa naplnení touto myšlienkou oddávajú ilúzii ovládania osudu ľudstva a manipulácie budúcnosti – to znamená, že toto ľudstvo sa môže hrať na Boha – vytvoriť utópiu „raja na zemi“. Toto je základná myšlienka komunizmu. Boj o dosiahnutie tohto takzvaného raja spôsobil ľudstvu záplavu krviprelievania a biedy.</p>
        <h3>c) Súčasný liberalizmus a&nbsp;progresivizmus: podoby komunizmu&nbsp;</h3>
        <h4 data-mce-word-list="1">Vzbura proti klasickému liberalizmu</h4>
        <p>Klasický liberalizmus vychádzajúci z filozofie prirodzených individuálnych práv obhajoval ústavné obmedzenie kráľovskej či štátnej moci s&nbsp;cieľom chrániť osobnú slobodu. Individuálne práva a dôstojnosť sú dané z nebies, pričom vláda je zostavená občanmi a má výslovnú povinnosť chrániť svojich občanov. Separácia cirkvi od štátu bola založená, aby sa zabránilo tomu, že vláda bude zasahovať do myšlienok a viery občana.</p>
        <p>Súčasný liberalizmus nie je nič iné ako zrada klasického liberalizmu v mene „slobody“, čo je dôsledok komunistickej infiltrácie. Na jednej strane zdôrazňuje absolútny individualizmus – t. j. extrémne oddávanie sa túžbam a nerešpektovanie morálky. Na druhej strane zdôrazňuje rovnosť výsledku namiesto rovnosti príležitostí.</p>
        <p>Napríklad pri diskusii o rozdelení bohatstva sa moderní liberáli zameriavajú iba na potreby príjemcov, a&nbsp;nie na práva daňových poplatníkov. Pokiaľ ide o metódy zamerané na riešenie diskriminácie, zameriavajú sa iba na tých, ktorí boli historicky ukrivdení, a ignorujú ľudí, ktorí sa stali obeťami týchto metód. V&nbsp;právnej oblasti bránia potrestaniu zločincov, aby údajne ochránili nevinných pred nespravodlivým odsúdením alebo aby ochránili znevýhodnených, ktorí sú údajne obeťami útlaku. Vo vzdelávaní ignorujú potenciál talentovaných študentov pod zámienkou, že musia podporiť a pomôcť žiakom s nízkou úrovňou vzdelania a žiakom zo znevýhodnených rodín. Používajú výhovorku slobody prejavu, aby odstránili obmedzenia týkajúce sa vydávania obscénnych alebo pornografických materiálov.</p>
        <p>Zameranie súčasného liberalizmu sa potichu posunulo od obhajovania slobody k presadzovaniu rovnosti. Napriek tomu sa bráni označeniu „rovnostárstvo“, pretože by ho okamžite identifikovalo ako formu komunizmu.</p>
        <p>John Locke, známy ako otec liberalizmu, vyjadril vo svojom „Liste ohľadom tolerancie“ svoje názory na náboženskú toleranciu a odluku cirkvi a štátu. Podľa Lockeho je hlavným aspektom tolerancie to, že štát, ktorý má donucovaciu právomoc, musí rešpektovať a tolerovať osobné presvedčenie jednotlivca. Či je presvedčenie človeka o svojej ceste do neba správne alebo nesprávne, o tom rozhodnú nebesá. Každý by mal mať svoju vlastnú dušu pod kontrolou. Štát by nemal využívať svoju moc na vnucovanie nejakej konkrétnej viery alebo neviery.</p>
        <p>Tolerancia, ktorú obhajuje klasický liberalizmus, je naozajstná cnosť, ktorá si zaslúži podporu, no &nbsp;komunizmus si „toleranciu“ privlastnil a&nbsp;spravil z&nbsp;nej cestu morálnej korupcie. Súčasný liberalizmus zanedbáva skutočný účel tolerancie a pretvára ju na absenciu súdnosti. Rozvinul politický koncept „bez hodnoty“, čo v skutočnosti znamená stratu morálnych zábran a zamieňanie dobra so zlom a cnosti s neresťou. Používa atraktívne frázy, aby otvoril dvere prívalu démonických konceptov a pod rúškom slobody bojuje proti morálke a tradícii.</p>
        <p>Napríklad v súčasnosti sa tolerancia často zneužíva ako neprimeraná obrana hnutia LGBT, ktoré je typickým obrazom bezhodnotového konceptu. Každý, kto vystúpi proti presadzovaniu životného štýlu LGBT, riskuje, že bude napadnutý pod zámienkou ochrany osobnej slobody a rovnosti a boja proti diskriminácii menšinovej skupiny.</p>
        <h4 data-mce-word-list="1">Podstata progresivizmu: morálny relativizmus</h4>
        <p>Je normálne, že pod vedením tradičných ľudských hodnôt používame našu inteligenciu na zlepšenie našich životných podmienok, zvýšenie bohatstva a dosiahnutie nových kultúrnych výšin. V „progresívnej ére“ amerických dejín od konca 19. storočia do začiatku 20. storočia boli vládnymi reformami napravené rôzne skorumpované praktiky, ktoré sa objavili v procese hospodárskeho a spoločenského rozvoja. No potom čo komunisti prenikli do Spojených štátov, uniesli pojmy ako „pokrok“ a „progresivizmus“ a naplnili ich škodlivou ideológiou. Pre komunizmus bolo iba logické, že zneužil progresivizmus, i keď to väčšine ľudí nebolo známe. Moderný progresivizmus je priamou aplikáciou Darwinových evolučných&nbsp; teórií v spoločenských vedách, ktorých dôsledkom je neustála odchýlka a prekrúcanie tradičnej morálky v mene „pokroku“. Aj dnes komunizmus pokračuje v otvorenom podvádzaní pod pláštikom progresivizmu.</p>
        <p>Počas tejto progresívnej revolúcie považovali ateisti tradičnú morálku za prekážku pokroku a požadovali prehodnotenie všetkých morálnych štandardov. Popierali existenciu absolútnych morálnych štandardov a využili spoločnosť, kultúru, históriu a súčasné podmienky na vytvorenie svojho systému relatívnej morálky.</p>
        <p>Morálny relativizmus je základným aspektom marxistickej ideológie. Tvrdí, že všetko, čo je v súlade so záujmami proletariátu (v podstate marxistickou vládnucou triedou), je morálne, pričom to, čo so záujmami proletariátu v súlade nie je, je nemorálne. Morálka sa nevyužíva na obmedzenie krokov proletariátu, ale ako zbraň, ktorú diktatúra proletariátu môže použiť proti svojim nepriateľom. Spolu s progresívnym hnutím získal tento morálny relativizmus vplyv v politike, v školstve, kultúre a v ďalších aspektoch západnej spoločnosti.</p>
        <p>Pre ľudí nie je nič zlé na tom, keď hľadajú šťastie a pokrok, ale keď určité „-izmy“ začnú nahrádzať normálne morálne hodnoty a presvedčenia, zmenia sa na nástroje, pomocou ktorých komunistický duch vedie ľudí k degenerácii a deštrukcii.</p>
        <h2>2. Smerovanie vlády pod kontrolu ľavice</h2>
        <p>Marx a Engels v komunistickom manifeste uvádzajú&nbsp; desať opatrení, ktoré majú zničiť spravodlivú výmenu a práva jednotlivca, ktoré nazývajú kapitalizmom. Mnoho z týchto opatrení sa už realizuje s cieľom postupne posunúť Spojené štáty a iné krajiny doľava a nakoniec zaviesť komunistickú politickú kontrolu, napríklad zaviesť „silne progresívnu alebo odstupňovanú daň z príjmu“ a „centralizovanie úverov v rukách štátu prostredníctvom národnej banky so štátnym kapitálom a výhradným monopolom“. Na povrchu sa zdá, že komunisti obhajujú niektoré pozitívne veci, ako napríklad koniec detskej práce v továrňach a zavedenie bezplatného verejného vzdelávacieho systému; ich cieľom však nie je zabezpečiť blaho národa, ale skôr zmocniť sa politickej moci a udržať si ju. Marx a Engels napísali:</p>
        <p><em>Prvým krokom v revolúcii pracujúcej triedy je pozdvihnúť proletariát do pozície vládnucej triedy, aby vyhral boj o demokraciu.</em></p>
        <p><em>Proletariát využije svoju politickú nadradenosť, aby postupne vybojoval všetok kapitál od buržoázie, aby centralizoval všetky výrobné nástroje do rúk štátu, t. j. proletariátu organizovaného ako vládnuca trieda; a čo najrýchlejšie zvýšil celkové výrobné sily.</em></p>
        <p><em>Samozrejme na začiatku je to možné uskutočniť len pomocou despotických zásahov do vlastníckych práv a podmienok buržoáznej výroby; prostredníctvom opatrení, ktoré sa javia ako ekonomicky nedostatočné a neudržateľné, ale ktoré v priebehu času predbehnú sami seba, čím si vyžiadajú ďalšie narušenie starého spoločenského poriadku a sú nevyhnutné ako prostriedok k úplnému prevráteniu výrobného postupu.<a href="#_edn2" id="_ednref2">[2]</a></em></p>
        <p>V Amerike ľavica strávila desaťročia bojom v Marxovej „bitke o demokraciu“, aby ovládala páky moci a zaviedla socializmus. A&nbsp;tak, hoci zjavných komunistických vplyvov v Spojených štátoch bolo počas 20. storočia relatívne málo, situácia sa drasticky zmenila.</p>
        <p>V amerických voľbách v rokoch 2016 a 2020 sa jeden z nádejných kandidátov na prezidenta otvorene hlásil k socializmu. Na socializmus, ktorý je v slovníku komunistov len „prvou fázou“ komunizmu, sa väčšina Američanov predtým pozerala s dešpektom. Aj sám kandidát povedal, že veľa ľudí veľmi znervóznie, keď počujú slovo „socialista“.</p>
        <p>Prieskumy uskutočnené v&nbsp;uplynulom desaťročí ukázali, že zhruba polovica ľudí narodených v miléniovej generácii (v rokoch 1980 až 1996) mala pozitívny pohľad na socializmus. Prieskum spoločnosti Gallup z roku 2018 ukázal, že 57 % demokratov uviedlo, že majú pozitívny názor na socializmus.<a href="#_edn3" id="_ednref3">[3]</a> Tento prieskum ukázal, že socializmus vníma pozitívne 49 % amerických občanov vo veku do 30 rokov, zatiaľ čo 46 % má pozitívny pohľad na kapitalizmus.<a href="#_edn4" id="_ednref4">[4]</a></p>
        <p>Ilúzia, ktorú má dnes o socializme toľko ľudí na Západe, zrkadlí skúsenosti veľkého počtu ovplyvniteľných mladých ľudí v Sovietskom zväze, v Číne a inde, ktorí v minulom storočí prijali komunizmus. Tým, ktorí patria k&nbsp;tejto mladšej generácii, chýba hlbšie pochopenie svojich vlastných dejín, kultúry a tradícií. Socializmus sa im javí ako mierumilovný a humánny, a preto sa proti nemu nijako nestavajú. Veľký komunistický podvod z 20. storočia sa preto opakuje aj v 21. storočí.</p>
        <p>Marxov princíp „Každý podľa svojich schopností, každému podľa jeho potrieb“ veľmi ľahko oklame mladých ľudí, ktorí snívajú o živote so štedrými sociálnymi dávkami, tak ako je to videné v rôznych častiach Európy. Avšak systém sociálneho zabezpečenia v týchto štátoch spôsobuje mnoho spoločenských problémov. Ako uviedol americký ekonóm Milton Friedman: „Spoločnosť, ktorá uprednostní rovnosť – v zmysle rovnosti výsledku – pred slobodou, skončí tak, že nebude mať ani rovnosť ani slobodu.... Na druhej strane, spoločnosť, ktorá dá na prvé miesto slobodu, dosiahne ako potešujúci vedľajší produkt oboje, väčšiu slobodu a väčšiu rovnosť.“<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Socializmus s vysokým sociálnym zabezpečením presadzuje neustálu expanziu vlády a vedie ľudí k tomu, aby si odhlasovali zmenšovanie svojich slobôd. Ide o dôležitý krok v pláne komunistického ducha, ako si zotročiť ľudstvo. Hneď ako prejdú takmer všetky národy na socializmus, je odtiaľ len pár jednoduchých krokov pre nahradenie demokracie totalitou. Hneď ako sa dovŕši socialistická „primárna fáza“, politickí vodcovia okamžite zavedú komunizmus. Súkromný majetok a demokratické procesy budú opustené a sociálny štát sa premení v jarmo tyranie.</p>
        <p>Aby mohol komunizmus vstúpiť do hlavného prúdu politiky USA, musí infiltrovať jednu alebo obe z dvoch hlavných strán a použiť ich k ovládnutiu hlasov v Kongrese. Medzičasom musia komunistickí kandidáti zaujať kľúčové pozície vo vláde a na súdoch. Hoci sa to nezdá, rozmer, v akom komunizmus dnes rozvrátil americkú politickú scénu, je dosť závažný. Aby si ľavicové strany v USA zabezpečili stabilnú voličskú základňu, zveličujú nepriateľstvo medzi skupinami s nízkym a vysokým príjmom a priťahujú stále narastajúci počet utečencov a „bezbranných“ skupín ako LGBT, žien, menšín a podobne.</p>
        <p>Istý miliardár, ktorý dlhodobo podporuje ľavicové hnutie, silne dotoval ľavicových kandidátov, ktorí sa uchádzali o post prezidenta Spojených štátov a ďalšie dôležité pozície po celej krajine. Sú medzi nimi štátni činitelia, ktorí majú v mnohých štátoch USA na starosti volebné záležitosti a hrajú dôležitú úlohu pri riešení rozporov. Tento miliardár investoval do kampaní na tieto pozície značné sumy.<a href="#_edn6" id="_ednref6">[6]</a></p>
        <p>Keď ilegálni prisťahovalci spáchajú na americkej pôde zločiny, ľavicové úrady to ignorujú a namiesto toho vytvoria azyl, ktorý takéto osoby chráni pred štátnou mocou. Navyše ľavicové strany bojovali o hlasy ilegálnych prisťahovalcov. Samozrejme, motívom nie je vždy pomôcť imigrantom či verejnosti, ale posilniť ľavicovú volebnú základňu. Jedno mesto blízko Washingtonu, D.C schválilo 12. septembra 2017 zákon, ktorý udeľuje ľuďom, ktorí nie sú občanmi Spojených štátov, právo voliť v miestnych voľbách. Zahŕňa to vlastníkov zelenej karty, osoby s dočasným pobytom a študentskými a pracovnými vízami a dokonca aj tých, ktorí nemajú žiadnu dokumentáciu o legálnom prisťahovaleckom statuse. Pritiahlo to veľkú mediálnu pozornosť kvôli potenciálnemu vplyvu na volebný systém v iných častiach krajiny.<a href="#_edn7" id="_ednref7">[7]</a></p>
        <p>Administratíva 44. amerického prezidenta bola silne infiltrovaná komunistami a socialistami. Mnoho skupín, ktoré podporovali tohto prezidenta, malo jasné väzby na socialistické organizácie. Samotný prezident udelil amnestiu pre&nbsp; takmer milión ilegálnych prisťahovalcov, keď v&nbsp;kongrese neprešla legislatíva, ktorá by to umožnila. Tento bývalý prezident je nasledovníkom para-marxistu Saula Alinského. Po jeho zvolení si za poradcov zvolil expertov z úplne ľavicových analytických organizácií a&nbsp;prišiel so systémom všeobecnej zdravotnej starostlivosti, ktorý ukladá pokutu tým, ktorí sa do neho odmietajú prihlásiť. Podporoval ľavicové ciele zastavením presadzovania federálnych zákonov proti marihuane, podporoval legalizáciu sobášov homosexuálov, umožnenie vstupu transsexuálov do armády a tak ďalej. V roku 2016 jeho administratíva vydala smernicu pre verejné školy, ktorá umožňovala študentom, ktorí sa identifikovali ako transsexuáli, aby vstupovali do toalety podľa svojho vybratého pohlavia, bez ohľadu na ich fyzické pohlavie – inými slovami, chlapci a muži mohli vstupovať na dievčenskú toaletu len na základe toho, že sa identifikovali ako ženy a naopak. Školám bolo povedané, že ak odmietnu zaviesť tento zákon, prídu o federálne financovanie. V reakcii na to koalícia trinástich štátov žalovala federálnu vládu s argumentom, že smernica je protiústavná.</p>
        <h2>3. Nenávisť a boj: nemenný priebeh komunistickej politiky</h2>
        <p>Boj a nenávisť sú dôležitou súčasťou komunistickej politiky. Ako primárny prostriedok ku zničeniu spoločnosti a zvráteniu jej morálky používa štvanie ľudí proti sebe, zasiatím nenávisti a&nbsp;rozdelením, pričom cieľ komunizmu je chopiť sa politickej moci a zriadiť diktatúru</p>
        <p>Mao v roku 1926 vo svojom článku: „Analýza tried v čínskej spoločnosti“ napísal: „Kto sú naši nepriatelia? Kto sú naši priatelia? Táto otázka je v revolúcii otázkou číslo jeden.“ Komunistická strana svojvoľne vytvára koncepty tried, kde predtým žiadne neboli, a potom nabáda tieto skupiny, ktoré svojvoľne rozdelila, aby proti sebe bojovali. Toto je tajná zbraň komunistov, ktorú využívajú pri svojom nástupe k moci.<a href="#_edn8" id="_ednref8">[8]</a> Aby komunistická strana presadila svoju agendu, vyberie určité problémy prameniace z poklesu morálnych hodnôt, a tie potom zveličí. Potom vyhlási, že základnou príčinou týchto problémov nie je morálna slabosť, ale štruktúra spoločnosti. Označí konkrétne triedy v spoločnosti za utláčateľov a navádza ľudí, aby proti týmto triedam bojovali a tým vyriešili spoločenské neduhy.</p>
        <p>Nenávisť a bojovanie komunistov sa neobmedzuje iba na konflikt medzi robotníkmi a kapitalistami. Kubánsky komunistický vodca Fidel Castro povedal, že nepriateľom kubánskeho ľudu je korupcia bývalého prezidenta Fulgencia Batistu a jeho stúpencov a že údajný útlak zo strany veľkých plantážnikov je zdrojom nerovnosti a nespravodlivosti. Komunistická strana sľubuje, že po zvrhnutí takzvaných utláčateľov nastane rovnostárska utópia. Castro a jeho spolu-revolucionári použili tento sľub, aby sa chopili moci na Kube.</p>
        <p>Mao Ce-tung prišiel v Číne s inováciou, keď sľúbil roľníkom, že budú môcť vlastniť pôdu, ktorú obrábali, robotníkom, že budú vlastniť továrne a intelektuálom, že sa dočkajú slobody, mieru a demokracie. To obrátilo roľníkov proti vlastníkom pôdy, robotníkov proti kapitalistom a intelektuálov proti vláde, čo dovolilo ČKS, aby si uzurpovala moc.</p>
        <p>V Alžírsku vyvolal komunistický vodca Ahmed Ben Bella nenávisť medzi rôznymi náboženstvami a národmi: postavil moslimov proti kresťanom a Arabov proti Francúzom. To sa stalo odrazovým mostíkom Ben Bellu k zaisteniu si komunistickej vlády.</p>
        <p>Otcovia zakladatelia v Spojených štátoch postavili krajinu na princípoch stanovených v americkej ústave. Rodina, cirkev a komunita tradične mali v americkej spoločnosti silné väzby a&nbsp;spoločnosť sa stala ešte prosperujúcejšou v priebehu devätnásteho a dvadsiateho storočia. Úspech amerického sna znižoval koncept spoločenských tried a sťažil zosnovanie triedneho boja v Spojených štátoch.</p>
        <p>Ale komunizmus využíva každú príležitosť, aby podnietil nejednotnosť. Za pomoci odborov zveličil konflikty medzi zamestnancami a zamestnávateľmi. Rasové rozdiely použil k podnieteniu bojov medzi rôznymi skupinami. Podporuje hnutie za ženské práva, ktoré boli namierené proti tradičnej sociálnej štruktúre. Zasial rozpory využitím sexuálnej orientácie a hnutia LGBT. Rozdeľuje veriacich rôznych náboženstiev a používa „kultúrnu diverzitu“, aby spochybnil tradičnú západnú kultúru a&nbsp;jej kultúrny odkaz. Rozdeľuje ľudí rôznych národností presadzovaním „práv“ pre nelegálnych prisťahovalcov a vytvára spory medzi cudzincami a občanmi danej krajiny. Stavia nelegálnych prisťahovalcov a verejnosť proti policajtom.</p>
        <p>S tým, ako sa spoločnosť stáva čím ďalej rozdrobenejšia, jediný chybný krok môže viesť k boju. Do sŕdc ľudí bola zasiata nenávisť a presne to je zlovestným cieľom komunizmu. Často sa cituje Leninov výrok: „Môžeme, ba musíme písať jazykom, ktorý zasieva medzi masami nenávisť, zhnusenie, pohŕdanie a&nbsp;podobne voči tým, ktorí s nami nesúhlasia.“<a href="#_edn9" id="_ednref9">[9]</a> Politické taktiky používané na Západe využívajú všetky možné podoby „sociálnej spravodlivosti“, aby vyvolávali nenávisť a zosilňovali spoločenské konflikty.</p>
        <p>V roku 1931 v&nbsp;prípade „Chlapci zo Scottsboro“ bolo deväť černošských mladíkov obvinených zo znásilnenia dvoch bielych žien, čo odštartovalo vážny rasový rozkol naprieč celými Spojenými štátmi. Komunistická strana USA incident použila k získaniu mnohých nových stúpencov, medzi nimi aj Franka Marshalla Davisa, budúceho poradcu 44. prezidenta. Cieľom amerických komunistov v prípade „Chlapcov zo Scottsboro“ bolo nielen zvýšiť počet členov z radov černošského obyvateľstva a progresívnych aktivistov za „sociálnu spravodlivosť“, ale aj vyobraziť Ameriku v zlom svetle ako krajinu plnú nerovnoprávností a rasovej diskriminácie. Vyhlasujúc, že takéto podmienky prevažujú v celých USA, vyzdvihovali komunizmus a ľavicovú ideológiu ako jediný spôsob, ako Američanov od tohto údajne patologického a zlého systému oslobodiť.</p>
        <p>V roku 1935 prepukli medzi černošskými komunitami v newyorskom Harleme nepokoje. Príčinou boli chýry, že bol na smrť ubitý istý černošský mladík, ktorý bol chytený pri krádeži v obchode. (V skutočnosti mladistvý z Portorika pohrýzol obchodného asistenta a sám neutrpel žiadne zranenia.) Ako priznal Leonard Patterson, bývalý člen KSUSA, ktorý zohral v celom incidente svoju úlohu, KSUSA túto príležitosť využila a zorganizovala protesty. Patterson opísal, že komunisti boli špeciálne vycvičení v leninistických taktikách na vyvolávanie a roznecovanie konfliktov - napríklad ako premeniť protesty na výtržnosti a pouličné boje, a ako zámerne vykonštruovať konflikt aj tam, kde predtým žiadny nebol.<a href="#_edn10" id="_ednref10">[10]</a></p>
        <p>Presuňme sa do súčasnosti. Komunistické skupiny v Amerike sú zapojené do každého väčšieho sociálneho rozbroja alebo nepokoja. V roku 1991 médiá široko publikovali zábery z Los Angeles, ktoré ukazovali černošského občana Rodneyho Kinga, ako ho bijú bieli policajti po naháňaní vo veľkej rýchlosti. V široko sledovanom klipe však bolo vystrihnutých prvých 15 sekúnd záznamu, ktoré ukazoval Kinga, prepusteného zločinca, ako vzdoroval zatknutiu a&nbsp;správal sa agresívne, zatiaľ čo jeho spoločníci vo vozidle políciu uposlúchli. Štyria policajti zúčastnení na incidente boli nakoniec obvinení z trestného činu. Po verdikte, práve vo chvíli, keď sa demonštrujúci pri sídle policajného oddelenia v Los Angeles chystali rozísť, ich niečo náhle vyprovokovalo k nepokojom. Niekto udrel kovovou značkou do kufra prechádzajúceho auta. Protest sa okamžite zvrhol do násilností a demonštranti rozbíjali, pálili a rabovali.<a href="#_edn11" id="_ednref11">[11]</a></p>
        <p>Keď sa miestneho šerifa Shermana Blocka opýtali, či sa na incidente podieľali komunisti, vyhlásil, že nie je pochýb o tom, že výtržností, rabovania a podpaľačstva sa zúčastnila Revolučná komunistická strana. Počas celej doby sa všade po uliciach a na školských dvoroch nachádzali letáčiky rôznych komunistických skupín, ako Revolučná komunistická strana, Socialistická robotnícka strana, Progresívna strana práce a Komunistická strana USA. Na jednom z letákov stálo: „Pomstite Kingov rozsudok! ...Všetci rasistickí policajti musia zaplatiť!... Obráťte zbrane proti nim! Vojaci, spojte sa s robotníkmi! ...“ Podľa jedného policajného dôstojníka z policajného oddelenia Los Angeles ľudia rozdávali takéto letáky ešte predtým, ako bol rozsudok vôbec oznámený.<a href="#_edn12" id="_ednref12">[12]</a></p>
        <p>Z tej záplavy organizácií, ktoré dnes podnecujú k výtržnostiam a násiliu v západnej spoločnosti, či už si hovoria „Indivisible“, „Antifašisti“, „Stop patriarchátu“, „Black Lives Matter“ alebo „Refuse Fascism“, všetko sú to komunistické skupiny či zástancovia komunistických myšlienok. Agresívna skupina Antifa sa skladá z ľudí rôzneho komunistického razenia, od anarchistov, socialistov, cez liberálov až po sociálnych demokratov a im podobných. Refuse Fascism je v skutočnosti radikálne zoskupenie založené ľavičiarmi, vrátane Revolučnej komunistickej strany USA. Táto skupina stojí za radom veľkých demonštrácií, ktoré sa snažili zvrátiť výsledok amerických prezidentských volieb v roku 2016.</p>
        <p>Tieto skupiny pod zámienkou uplatňovania slobody prejavu neúnavne pracujú, aby v spoločnosti vyprovokovali všemožné konflikty. Pre pochopenie ich skutočného zámeru je nutné pozrieť sa na smernicu Komunistickej strany USA z roku 1943, ktorú vydala pre svojich členov:</p>
        <p><em>Keď sú určití ľudia s obštrukciami (voči komunizmu) príliš otravní, po vhodných prípravách ich označte za fašistov alebo nacistov alebo antisemitov a na ich diskreditáciu využite prestíž organizácií vyzdvihujúcich antifašizmus a toleranciu. V mysliach verejnosti neustále prirovnávajte tých, ktorí sú proti nám, k menám, ktoré majú zlú povesť. Toto spojenie sa v mysliach verejnosti stane po dostatočnom počte opakovaní „faktom“. Členovia a popredné organizácie musia bez oddychu hanobiť našich kritikov, diskreditovať ich a&nbsp;ponižovať ich.<a href="#_edn13" id="_ednref13">[13]</a></em></p>
        <h2>4. Politika násilia a lží</h2>
        <p>V komunistickej doktríne sa žiadne prostriedky nepovažujú za príliš extrémne. Komunistické strany verejne vyhlasujú, že násilie a lži sú ich nástrojmi na podmanenie a ovládnutie sveta. Od chvíle, kedy sa komunistický režim prvýkrát objavil v Sovietskom zväze, počas jediného storočia privodil smrť prinajmenšom sto miliónom ľudí. Členovia komunistickej strany unášajú ľudí, mučia ich a vraždia, ničia a klamú. Používajú na to akékoľvek extrémne metódy. Miera ich zla je šokujúca. Navyše tí, ktorí sa na komunistickom násilí podieľali, necítia nad svojimi činmi žiadnu ľútosť.</p>
        <p>Lži vymyslené komunistami majú rôznu veľkosť, a to ako v komunistických krajinách, tak aj na Západe. Od relatívne&nbsp; malých lží,&nbsp; ako zavádzajúce a falošné správy alebo falošné obvinenia politických oponentov, až po systematické lži značného rozsahu v rámci komplexných operácií. Napríklad ČKS uskutočnila incident sebaupálenia na Námestí nebeského pokoja a obvinila členov duchovnej praxe Falun Gong – všetko za účelom podnecovania nenávisti verejnosti proti tejto praxi.</p>
        <p>Strana používa aj tzv. veľké lži alebo veľké podvody, ktoré sa však ťažko realizujú, pretože veľké lži sa takmer rovnajú celej komunistickej ideológii. Ich rozsah je taký obrovský, ich pôsobenie také mnohostranné, ich trvanie také dlhé a ľudí, ktorých sa dotýkajú, je tak mnoho – vrátane tých, ktorí sú danej veci úprimne oddaní – že sa pravda o situácii stratí. Komunistický duch vykonštruoval lož, že „veľká jednota“ je cieľom komunizmu. Pretože toto tvrdenie nebolo možné vyvrátiť (aspoň nie v krátkej dobe), na tejto veľkej lži bol postavený celý projekt komunizmu. Komunisti tvrdia, že zakladajú nebo na Zemi, ale toto je presne ich najväčšia lož a ​​jediné ovocie, ktoré táto lož priniesla, je peklo na Zemi.</p>
        <p>Predchádzajúca kapitola analyzovala, ako si komunizmus prisvojil pojem progresivizmus&nbsp; prostredníctvom ďalšieho veľkého podvodu. V niekoľkých posledných desaťročiach sa komunizmus zmocnil mnohých sociálnych hnutí a priniesol ľuďom chaos a revolúcie. Tomuto sa budeme venovať v ďalších kapitolách.</p>
        <h3>a. Ako komunizmus používa násilie a klamstvá</h3>
        <p>Komunistické strany nabádajú k triednemu boju – a tento boj je bojom na život a na smrť. Ako sa hovorí v Komunistickom manifeste: „Komunisti pokladajú za nedôstojné, aby tajili svoje názory a úmysly. Otvorene vyhlasujú, že ich ciele možno dosiahnuť len násilným zvrhnutím celého doterajšieho spoločenského poriadku.“<a href="#_edn14" id="_ednref14">[14]</a> Lenin v knihe&nbsp; <em>Štát a revolúcia</em> napísal: „Povedali sme už a dokážeme podrobnejšie v ďalšom výklade, že Marxova a Engelsova teória o nevyhnutnosti násilnej revolúcie sa týka buržoázneho štátu. Buržoázny štát nemôže byť nahradený proletárskym štátom (diktatúrou proletariátu) procesom ‚pomalého odumierania‘, ale iba násilnou revolúciou.“<a href="#_edn15" id="_ednref15">[15]</a></p>
        <p>Či už išlo o Parížsku komúnu, ruskú revolúciu alebo vzostup ČKS, komunistické strany sa v rámci uzurpovania moci uchýlili k extrémne násilným a krvavým metódam. Bez ohľadu na to, či sú ich nepriatelia mladí alebo starí, silní alebo slabí, komunistické strany ich pália, okrádajú a vraždia, prejavujú také zlo, ktoré človeka šokuje. Zločinov, ktoré násilnícke komunistické režimy spáchali, je toľko, že je takmer nemožné ich spočítať.</p>
        <p>Aby si komunistický kult udržal moc, používa násilie a klamstvá. Klamstvá poháňajú násilie a tiež sú spôsobom zotročenia verejnosti. Komunistické strany sú ochotné sľúbiť čokoľvek, nikdy však neuvažujú o tom, že svoje sľuby splnia. Aby uspokojili svoje potreby, menia príbehy tak, ako sa im chce, bez akýchkoľvek morálnych zábran alebo pocitu hanby.</p>
        <p>Či už to bol Mao Ce-tung v Číne, Fidel Castro na Kube alebo Ahmed Ben Bella v Alžírsku, tí všetci tvrdili, že nikdy nezavedú totalitný režim. Ale hneď ako sa dostali k&nbsp; moci, okamžite zaviedli tvrdú totalitu, uskutočňovali čistky v&nbsp;strane, ako aj kampane prenasledovania&nbsp; disidentov a bežných občanov.</p>
        <p>Okrem toho je jazyková manipulácia jednou z hlavných metód, ktoré komunistický kult používa na klamanie ľudí – to znamená, že mení význam slov a dokonca z nich robí protiklady. Keď sa zmenený jazyk opakovane používa, jeho skreslené významy sa hlboko zakorenia v mysliach ľudí. Napríklad „Boh“ sa rovná „povere“; „tradícia“ znamená „zaostalosť“, „hlúposť“ a „feudalizmus“; „západná spoločnosť“ sa rovná „nepriateľ“ alebo „protičínske sily“; a „proletariát“ sa stáva „pánom štátneho majetku“. Aj keď ľudia za komunizmu nemajú moc, komunisti tvrdia, že „všetka moc patrí ľuďom“. Poukazovanie na nespravodlivosť sa označuje ako „podnecovanie rozvratu štátnej moci“. Preto pri rozhovore s tými, ktorých komunistický kult hlboko otrávil, si ľudia môžu všimnúť, že obom stranám často chýba spoločný základ pre komunikáciu, keďže sa nedokážu zhodnúť ani na významoch slov.</p>
        <p>Komunistický kult nielenže klame, ale tiež vytvára prostredie, ktoré núti klamať celú populáciu – k čomu využíva napr. nútené politické štúdie, povinné vyhlásenia ľudí o svojom politickom postoji a politické previerky. Slúži to na prinútenie ľudí hovoriť to čomu neveria, a tak ich demoralizovať a narušiť ich zmysel pre správne a nesprávne. Keď si ľudia uvedomia komunistické klamstvá, reagujú svojimi vlastnými klamstvami. Komunistický kult vie, že mu ľudia klamú, no je to preň prijateľné, pretože samotné klamstvo je súčasťou hry. Pre komunistov je nebezpečné, keď ľudia začnú hovoriť pravdu.</p>
        <p>Presadzovanie kultúry klamstva je prostriedkom morálnej degenerácie, ktorú komunisti vytvorili. Táto kniha opakovane uvádza, že režim ČKS si želá nielen zabiť fyzické telo, ale spôsobiť aj extrémny morálny úpadok. V tomto ohľade duch komunizmu čiastočne dosiahol svoj cieľ.</p>
        <h3>b. Podnecovanie násilia na Západe</h3>
        <p>Duch komunizmu je vytvorený zo živelnej sily nenávisti, preto sú jej plné i jeho teórie. Podporuje triedny boj a príčinu každého problému prisudzuje tradičným sociálnym štruktúram. Hovorí o bohatých, ako vykorisťujú chudobných, s cieľom podnietiť nevôľu a nenávisť voči bohatým a podnecuje revolúciu a násilie. S expanziou komunistických hnutí sa jeho manipulácia, násilie a klamstvá stali na Západe bežnou záležitosťou a spoločnosť naplnili nevraživosťou.</p>
        <p>Spoločnosť s väčšou tendenciou k násiliu bude menej stabilná a viac rozdelená. V americkej spoločnosti útočia niektorí politici a politickí činitelia na svojich nepriateľov nečestnými prostriedkami, ako sú klamstvá, osobné útoky a podobne. V dnešnej dobe sa neprekonateľné rozdiely medzi dvoma hlavnými stranami v USA javia nezlučiteľné ako oheň a voda.</p>
        <p>Ľavicovo orientované strany a politici tvrdia, že budú ochraňovať práva ľudí a riadiť sa predpismi demokratickej spoločnosti, ale hneď ako sa dostanú k moci, pod vplyvom komunistického ducha použijú všetky metódy, aby sa zbavili kritikov a svojvoľne pripravujú ostatných o ich práva.</p>
        <p>Nie každý chce konflikt, stačí však pár kľúčových komunistických aktivistov, aby rozpútali nepokoje. Po prezidentských voľbách v roku 2016 sa extrémisti z hnutia Antifa zamerali na svoj cieľ – konzervatívcov – a útočili na nich na zhromaždeniach a na iných miestach. Bránili stúpencom prezidenta a&nbsp;konzervatívne zmýšľajúcim ľuďom, aby rozprávali na verejných podujatiach, a v niektorých prípadoch ich dokonca fyzicky napadli.</p>
        <p>V júni 2017 na baseballovom tréningu postrelil republikána a člena Snemovne reprezentantov Steva Scalisea podporovateľ inej strany. Jeden ľavicový politik z Nebrasky dokonca vyhlásil, že je „rád“, že Scalisea postrelili, a že by si prial, aby zomrel. Čoskoro potom bol zo svojho postu predsedu výboru strany na štátnej úrovni odvolaný.</p>
        <h3>c. Ako komunizmus pletie Západ klamstvami</h3>
        <p>Komunizmus má na Západe zlú povesť, takže klamstvo je jediným prostriedkom na rozšírenie jeho vplyvu. Niektorí politici presadzujú politiku, ktorá je čisto komunistická, no prichádza zaobalená do iných názvov a používa slogany ako „sloboda“, „pokrok (progres)“ a „verejný záujem“. Napríklad zriadenie socialistického systému zdravotnej starostlivosti sa nenazýva socialistické, ale namiesto toho sa použije názov „zdravotná starostlivosť pre všetkých“ alebo „univerzálna zdravotná starostlivosť“, prípadne je takýto systém ospravedlnený tak, že má podporu verejnosti. Keď chcú&nbsp; ľavicoví politici prinútiť zamestnávateľov, aby vyplácali určenú minimálnu mzdu, nazvú to „životné minimum“. Aby boli zvolení, rozdávajú ľuďom prázdne sľuby. Je to podobné tomu, čo robia komunistické strany, aby získali moc. V skutočnosti je ich cieľom presadiť socializmus a ich taktiky kopírujú komunistické sľuby o vytvorení „neba na Zemi“.</p>
        <p>V praxi ide o to, že politici používajú pokrivenú a redistribučnú fiškálnu a daňovú politiku – ako poskytovanie daňových stimulov odborom, vládnym programom a minoritným podnikom – zatiaľ čo zvyšujú dane ostatným podnikom a bohatým. Výsledkom je, že príjemcovia týchto stimulov (vrátane chudobných ľudí, odborových zväzov a tak ďalej) sa stanú závislí od politikov, ktorí ich uprednostňujú, a potom ich vo voľbách podporujú. Takí politici potom majú v danej oblasti stabilnú dlhodobú moc a môžu si budovať svoju politickú mašinériu. Zároveň sa tým ale zvyšuje finančná záťaž pre firmy, ktoré potom živoria&nbsp; alebo bankrotujú. Prípadne sa presťahujú, čo zas vedie k neustálemu znižovaniu vybraných daní a&nbsp;tiež &nbsp;pracovných príležitostí, čo napokon spôsobí, že toto mesto finančne zbankrotuje.</p>
        <p>V minulosti ľudia verili, že Spojené štáty sú skutočne slobodnou spoločnosťou a poslednou baštou proti komunizmu. Ale dnes ľudia jasne vidia, že aj v USA sa uzákoňujú a uvádzajú do praxe vysoké zdanenie, prebujnený sociálny štát, kolektivizmus, prebujnelá&nbsp; vláda, sociálna demokracia, „sociálna rovnosť“ a podobne, pochádzajúce zo socialistickej a marxisticko-leninistickej DNA. Najmä mladšia generácia jednoducho nevie o histórii brutality v komunistických krajinách. Túžia po iluzórnych ideáloch a usilujú sa o ne, ale v skutočnosti sú podvedení novou maskou, ktorú komunizmus prevzal. Výsledkom je, že nevedomky kráčajú po ceste k záhube.</p>
        <h2>5. Cesta k totalite</h2>
        <p>Totalitná kontrola komunistických režimov nad životmi svojich občanov je dobre zdokumentovaná. Avšak ideologické odnože komunizmu v demokratických krajinách sa nenápadne usilujú o rovnaký cieľ tým, že volajú po zákonoch, ktoré agresívne rozširujú vládnu moc a zvyšujú reguláciu spoločnosti a ekonomiky. Ešte &nbsp;desivejšia je skutočnosť, že dnešní autoritári majú k dispozícii vedu a techniku, ktoré im dávajú moc dohľadu a sociálnej kontroly v&nbsp;takom rozsahu, aký si tyrani minulosti mohli len ťažko predstaviť.</p>
        <h3>a. Odstránenie slobodnej vôle a potlačenie morálky</h3>
        <p>Ak ľudia nasledujú tradičné hodnoty ustanovené božstvom, vývoj ich kultúry bude nasledovať ortodoxnú cestu, čo je dôležitý most pre spojenie sa s nebesami. V rôznych krajinách sveta na základe ich kultúry vznikli rôzne sociálne a politické systémy.</p>
        <p>Stvoriteľ ľudí obdaril slobodnou vôľou a schopnosťou spravovať svoje vlastné záležitosti. Ľudia by sa mali riadiť sebadisciplínou, morálnym správaním a zodpovednosťou k sebe a k svojej rodine. Keď francúzsky politický filozof Alexis de Tocqueville študoval v 19. storočí americkú politiku, veľmi oceňoval jej prednosti. Zapôsobila na neho americká schopnosť introspekcie, ich pochopenie zla, ochota riešiť problémy s trpezlivosťou a všeobecná absencia násilia pri riešení sociálnych problémov. Podľa neho spočívala veľkosť a&nbsp;sila Spojených štátov v ich schopnosti naprávať vlastné chyby.</p>
        <p>Na druhej strane to, čo chce duch komunizmu, je podnietiť ľudí, aby sa postavili proti tradíciám a morálke, a zablokovať tak ľuďom cestu k dobru a k božskému. Ľudia v komunistických krajinách sú pretváraní tak, aby sa z detí božích stali poddanými diabla bez toho, aby si to všimli. V komunistických krajinách vláda monopolizuje sociálne zdroje, takže všetko sa musí diať podľa inštrukcií vodcov komunistickej strany, ktorí sami musia preukázať dostatočnú „stranícku povahu“, ak majú prežiť neľútostné boje medzi jednotlivými frakciami, ako je v komunistických režimoch bežné. Bežní občania alebo radové kádre, ktorí sa snažia nasledovať svoje svedomie a konať morálne, takmer vždy skončia tak, že porušia ideovú líniu strany a sú buď degradovaní alebo označení za nepriateľov štátu. Stane sa z nich spodina, nútená živoriť na okraji spoločnosti.</p>
        <p>V slobodných spoločnostiach sa vlády tiež uberajú k autoritárstvu, keď veľká vláda („big government“) začína ovládať takmer všetko. Jedným z kľúčových prvkov autoritárskej politiky je silná ústredná vláda, ktorá plánuje a riadi ekonomiku. V súčasnosti majú západné vlády obrovskú moc zasahovať do ekonomiky a riadiť ju, aby dosiahli vládne plány; používajú&nbsp; k tomu nástroje ako štátne príjmy a výdavky, zdanenie a financovanie dlhu.</p>
        <p>Rozšírenie centrálnej administratívnej moci, kontrola miestnej samosprávy nad životmi občanov a množstvo zákonov a súdnych rozhodnutí, majú za následok všestranné rozšírenie vládnej moci a bezprecedentnú spoločenskú kontrolu. „Politická korektnosť“ je výhovorkou, ktorá má zbaviť ľudí slobody prejavu. Tí, ktorí túto zlovestnú politiku otvorene kritizujú, sú odsudzovaní za „prejavy nenávisti“ („hate speech“). Tí, ktorí sa odvážia oponovať voči politickej korektnosti sú marginalizovaní, izolovaní a v niektorých prípadoch prepustení. V extrémnych prípadoch čelia vyhrážkam alebo napadnutiu.</p>
        <p>Odchýlené politické normy nahradili čestné morálne normy. Tieto normy sú potom vynucované prostredníctvom zákona, nariadení a verejných útokov, čo vytvára atmosféru spoločenského teroru a tlaku. Tento spoločenský teror potom môže potláčať slobodnú vôľu ľudí a ich slobodu usilovať o dobro. Taká je podstata totalitnej politiky.</p>
        <h3>b. Totalita prostredníctvom sociálneho zabezpečenia, technológie a nadmernej regulácie</h3>
        <p>Súčasná západná spoločnosť oplýva nadmierou zákonov a predpisov, ktoré ovládajú detaily takmer každého aspektu spoločnosti, od pracovných záležitostí až po výchovu detí. Systém sociálneho zabezpečenia je považovaný za bežnú nutnosť, a nie ako forma núdzovej pomoci pre skutočne znevýhodnených občanov. Pokroky v oblasti technológií umožňujú vládam presadzovať byrokraciu v doposiaľ nebývalej miere. Tento proces poháňajú a podporujú ľavicové skupiny a politici, ktorí ho vydávajú za pokrok.</p>
        <p>V skutočnosti predstavuje rozšírenie vládneho dohľadu a sociálneho zabezpečenia od štátu vážnu hrozbu pre slobodu a morálku. Ako napísal v 19. storočí Alexis de Tocqueville: „Keby sa mal medzi demokratickými národmi dneška zaviesť despotizmus, vyzeral by zrejme inak. Bol by rozsiahlejší a miernejší a degradoval by ľudí bez toho, aby ich mučil.“<a href="#_edn16" id="_ednref16">[16]</a></p>
        <p>Od federálnej úrovne, cez štátnu a okresnú, až po obecnú, sa každoročne prijímajú tisíce nových zákonov. Prakticky na všetko existuje nejaký zákon alebo predpis, ktorý to obmedzuje. Americký daňový zákon má desaťtisíce strán, pričom nedávny zákon o zdravotnom poistení má viac ako dvadsaťtisíc strán. Ani sudcovia a právnici nemôžu pochopiť všetky tie zákony, nieto ešte bežný občan. Človek môže porušiť zákon bez toho, aby si to vôbec uvedomil.</p>
        <p>Okrem toho, bez ohľadu na to, aký dokonalý môže byť zákon, je to iba vonkajšia forma obmedzenia a nemôže riadiť ľudské srdce. Ako povedal Lao-c’: „Čím viac zákonov sa schváli, tým viac bude zlodejov a banditov.“</p>
        <p>Ľudia ignorujú fakt, že sociálne problémy sú vyvolané tým, že popúšťajú uzdu zlej stránke človeka. S tým, ako vlády vytvárajú čoraz viac zákonov, ignorujú koreň celého problému, vzniká tak začarovaný kruh a spoločnosť sa krok po kroku vydáva na cestu k totalite.</p>
        <p>V priebehu dejín si schopné vlády zachovávali schopnosť prideliť zdroje chudobným, napríklad v časoch hladomoru, sucha alebo záplav. Zároveň prirodzene existovali charitatívne organizácie v miestnych komunitách a&nbsp;v náboženských organizáciách. Britský právnik A. V. Dicey vypozoroval, že vlády začali v 20. storočí považovať sociálne zabezpečenie pre jednotlivca za niečo, čo treba regulovať a&nbsp;poskytovať pomocou zdanenia:</p>
        <p><em>Pred rokom 1908 bola otázka, či si má človek, či už bohatý alebo chudobný, poistiť svoje zdravie, ponechaná úplne na slobodnom uvážení a zvážení každého jednotlivca. Jeho konanie štát nezaujímalo o nič viac ako to, či má nosiť čierny kabát alebo hnedý kabát. Ale z dlhodobého hľadiska Zákon o národnom poistení zaťaží štát, teda daňových poplatníkov, oveľa viac, než by anglickí voliči očakávali ... [Poistenie v nezamestnanosti] je vlastne priznaním štátu, že má za povinnosť poistiť človeka proti nešťastiu, ktoré plynie z toho, že nemá prácu. ... Zákon o národnom poistení je v súlade s doktrínami socializmu. ... <a href="#_edn17" id="_ednref17">[17]</a></em></p>
        <p>Štáty s rozsiahlym sociálnym systémom však majú mnoho neoddeliteľných slabostí. Neexistuje nič také ako obed zadarmo. Vysoká miera sociálneho zabezpečenia je založená na vysokom zdanení, čo vyvoláva celý rad sociálnych neduhov. Škandinávsky model socialistického sociálneho zabezpečenia bol kedysi považovaný za pozitívny príklad socialistickej prosperity, ktorý má Západ napodobňovať, avšak v severnej Európe je pomer zdanenia voči HDP jedným z najvyšších na svete, pričom mnoho štátov má dane vo výške okolo 50 %.</p>
        <p>Analytici poukazujú na niekoľko fatálnych problémov v oblasti socialistickej zdravotnej starostlivosti. Systém je neudržateľný, pretože ľudia chcú z bezplatných služieb ťažiť viac, než na ne prispievajú. Neexistujú odmeny ani postihy za výkon. Zdravotnícky personál nemusí za svoje činy niesť žiadnu právnu zodpovednosť. Vláde to prináša obrovské straty, pretože ľudia kradnú vďaka medzerám v systéme, zneužívajú ho a robia nekalé obchody. Vláda prostredníctvom zásahov do zdravotníctva rozhoduje o živote a smrti ľudí a je sužovaná byrokraciou.<a href="#_edn18" id="_ednref18">[18]</a></p>
        <p>V roku 2010 si 32-ročný muž menom Jonas v severnom Švédsku musel po hodinách čakania na lekársku starostlivosť zašiť svoju vlastnú krvácajúcu ranu. Potom ako sa nedopatrením porezal pri oprave domu, najskôr išiel do zdravotného strediska, ktoré bolo zatvorené, a odtiaľ na pohotovosť, kde musel hodinu čakať. Rana mu krvácala, ale personál na neho nemal čas. Nakoniec sa muž rozhodol, že si ranu zašije sám pomocou ihly a nite, ktoré v miestnosti nechala sestrička. Personál ho však nahlásil na polícii za porušenie zákona o neoprávnenom používaní nemocničného vybavenia bez povolenia.<a href="#_edn19" id="_ednref19">[19]</a> Toto je iba jeden príklad toho, ako môže socialistický systém sociálneho zabezpečenia viesť k smiešnym výsledkom. Pretože lekársku starostlivosť chce každý zadarmo, zneužívajú sa zdroje. Konflikt medzi dopytom a obmedzenými zdrojmi potom vedie k nevyrovnanosti medzi ponukou a dopytom. Nedostatočné zdroje vedú k dlhým čakacím dobám a neadekvátnej starostlivosti. Tým, ktorí skutočne potrebujú starostlivosť, tento socialistický spôsob vedenia zdravotníctva ubližuje.</p>
        <p>Systém sociálneho zabezpečenia od kolísky po hrob sa zdá pre mnohých ako niečo žiadúce, ale v skutočnosti je závislosť obyvateľov na vláde cestou k autokratickému režimu. Tento princíp sa jasne odráža v marxistickom chápaní, kde socializmus je považovaný za úvodné štádium komunizmu.</p>
        <p>Väčšie zapojenie orgánov štátu do regulácie spoločnosti alebo do života jednotlivých občanov si vyžaduje rozsiahlejší systémy štátnej kontroly: najímanie pracovníkov a príprava právnych predpisov si vyžaduje peniaze, ktoré sa získavajú zo zdanenia. S rozširovaním štátu prichádza aj vytváranie mocných politických zoskupení, ktoré majú zásadný záujem na zachovaní a rozšírení rozsahu svojich právomocí.</p>
        <p>Technológia vládam uľahčuje kontrolu nad obyvateľstvom. ČKS je toho najočividnejším príkladom, avšak rovnaké nebezpečenstvo hrozí aj v západných krajinách. Najmä v Európe sú už socialistické programy všadeprítomné.</p>
        <p>Čína má dnes najrozsiahlejší sledovací systém na svete. Či už na verejných miestach alebo na cestách, kamery sú všade. Len za pár minút je možné z databázy 1,4 miliardy ľudí vytiahnuť tváre, ktoré sú na čiernej listine. Monitorovací softvér zabudovaný do aplikácie WeChat na mobilných telefónoch umožňuje priame sledovanie. Majitelia mobilných telefónov teda môžu zabudnúť na súkromie. Nie je kam uniknúť.</p>
        <p>Ako sú technológie čoraz vyspelejšie a právomoci vlády rastú, aj Západ, napredujúci po ceste socializmu, sa dočká podobne ponurého osudu v podobe neustáleho sledovania, nátlaku a riadenia. Tento konečný scenár rozhodne nie je nič prehnaného.</p>
        <p>Okrem fyzického sledovania a cenzúry môže vláda použiť veľké dáta a finančné informácie, aby sa zamerala na nepohodlných občanov. Tí môžu byť vyhodení z práce, banky im môžu zrušiť hypotéku, odobrať živnostenské listy alebo odrezať prístup k&nbsp;sociálnemu zabezpečeniu, ktoré je vďaka iným ekonomickým politikám jediným prostriedkom ich živobytia.</p>
        <h2>6. Komunizmus ako hrozba pre základné hodnoty</h2>
        <p>Po celé stáročia zasievali komunistické ideológie do politickej sféry chaos. Na Východe komunistické režimy mobilizovali moc štátu, aby rozdrvili politických oponentov, zničili tradičnú kultúru a zabili desiatky miliónov ľudí. Na Západe ľavicové hnutia už pevne ovládli demokratický proces. Zatiaľ čo na povrchu sa vyhýbajú otvorenému násiliu a diktatúre, politika, ktorú presadzujú, nasleduje tú istú filozofiu boja.</p>
        <p>Túžba po moci, bohatstve a sláve je taká stará ako ľudstvo samo, pretože každý človek má v sebe dobrú i zlú stránku. Duch komunizmu využíva morálne slabosti, ktoré sú ľudskej povahe vrodené, a tak si dokázal vychovať sieť „agentov“ po celom svete.</p>
        <p>Kvôli komunistickej infiltrácii sú dnes spoločnosti na Západe rozdelené do nevídanej miery. Ľavica používa všetku svoju moc, aby bránila a prekazila plány tých, ktorí sa v politike držia tradičných hodnôt. Nie je prehnané tvrdiť, že Západ je teraz vo vojne o svoje vlastné hodnoty.</p>
        <p>Ukázalo sa, že ľavicový politický vplyv je mimoriadne odolný voči pokusom o jeho oslabenie či zvrátenie. Politici a aktivisti pod komunistickou taktovkou konšpirujú s médiami, aby zdiskreditovali opozíciu a šíria zavádzajúce informácie, ktorých cieľom je zmiasť verejnosť. Ľavicoví funkcionári ignorujú,&nbsp; bránia vládnym nariadeniam, presmerovávajú verejné zdroje, aby podporili svoje ideologické agendy a zavádzajú opatrenia, ktoré zväčšujú spoločenský rozkol a konflikt.</p>
        <p>Podľa prieskumu tlačovej agentúry AP a Strediska pre výskum verejných záležitostí (NORC) z roku 2018 viac ako 80 % respondentov uviedlo, že medzi Američanmi panuje hlboký rozkol ohľadom podstatných hodnôt a že krajina je na politickom poli rozdelená hlbšie ako v minulosti.<a href="#_edn20" id="_ednref20">[20]</a></p>
        <p>Štát má neporovnateľnú schopnosť zhromažďovať ľudské a ekonomické zdroje. Ak je politická moc používaná rozumne, prináša celému národu veľa kladného a zlepšuje medzinárodné spoločenstvo, ale ako vidíme v minulosti a v dejinách komunistického hnutia, zneužívanie tejto moci vedie k monštruóznym zločinom.</p>
        <p>Bývalý americký prezident Ronald Reagan vo svojom prvom inauguračnom príhovore vyhlásil: „Z času na čas nás to láka uveriť, že spoločnosť je príliš zložitá na to, aby sa dala spravovať samovládou, že vláda z rúk elitnej skupiny je nadradená vláde ľudu a pre ľud. No ak nikto z nás nie je schopný vládnuť samému sebe, kto teda medzi nami má schopnosť vládnuť niekomu inému? “<a href="#_edn21" id="_ednref21">[21]</a> Prezident Donald Trump uviedol, že v Spojených štátoch „neuctievame vládu – uctievame Boha“.<a href="#_edn22" id="_ednref22">[22]</a></p>
        <p>Jednota krajiny si vyžaduje spoločný súbor hodnôt a spoločnú kultúru. Hoci sa doktríny jednotlivých náboženstiev líšia, meradlá pre to, čo je dobré a čo zlé, sú si podobné. Vďaka tomu môžu spolu ľudia rôzneho etnického a kultúrneho pôvodu v Spojených štátoch žiť v harmónii. Ak sa však ľudia v otázkach základnej morálky rozchádzajú, potom je v stávke samotné prežitie krajiny.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Jules-Antoine Castagnary,&nbsp;Philosophie du Salon de 1857,&nbsp;citované z Franklina L. Baumera,&nbsp;Modern European Thought: Continuity and Change in Ideas,&nbsp;1600–1950 (New York: Macmillan, 1977), 335.</p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> Karl Marx and Friedrich Engels, „Komunistický manifest“, z <em>Vybraných spisov Marxe a Engelse</em>,&nbsp;<em>1. vydanie (v angličtině)</em>, preklad Samuel Moore, ed. Andy Blunden, Marxistický internetový archív, stránka navštívená 17. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch02.htm"></a><a href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch02.htm">https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch02.htm</a>.</p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> Frank Newport, „Democrats More Positive About Socialism Than Capitalism”,&nbsp;<em>Gallup,</em>&nbsp;13. augusta 2018, <a href="https://news.gallup.com/poll/240725/democrats-positive-socialism-capitalism.aspx"></a><a href="https://news.gallup.com/poll/240725/democrats-positive-socialism-capitalism.aspx">https://news.gallup.com/poll/240725/democrats-positive-socialism-capitalism.aspx</a>.</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> „Little Change in Public’s Response to ,Capitalism’, ,Socialism’”,&nbsp;<em>Pew Research Center,</em>&nbsp;28. prosince 2011,&nbsp;<a href="https://www.people-press.org/2011/12/28/little-change-in-publics-response-to-capitalism-socialism"></a><a href="https://www.people-press.org/2011/12/28/little-change-in-publics-response-to-capitalism-socialism">https://www.people-press.org/2011/12/28/little-change-in-publics-response-to-capitalism-socialism</a>.</p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> Milton Friedman, Rose D. Friedman,&nbsp;<em>Free to Choose: A&nbsp;Personal Statement</em>, (Slobodný výber: Osobné vyhlásenie)&nbsp; (Boston: Mariner Books, 1990), 148</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Matthew Vadum, „Soros Election-Rigging Scheme Collapses: The Secretary of State Project’s death is a&nbsp;victory for conservatives“,&nbsp;FrontPage Magazine, 30.&nbsp;júla 2012,&nbsp;<a href="https://www.frontpagemag.com/fpm/139026/soros-election-rigging-scheme-collapses-matthew-vadum.">https://www.frontpagemag.com/fpm/139026/soros-election-rigging-scheme-collapses-matthew-vadum.</a></p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> Rachel Chason, „Non-Citizens Can Now Vote in College Park, Md.“,&nbsp;Washington Post, 13. septembra 2017,&nbsp;</p>
        <p><a href="https://www.washingtonpost.com/local/md-politics/college-park-decides-to-">https://www.washingtonpost.com/local/md-politics/college-park-decides-to-</a></p>
        <p>allow-noncitizens-to-vote-in-local-elections/2017/09/13/2b7adb4a-987b-11e7-87fc-c3f7ee4035c9_story.html?utm_term=.71671372768a.</p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> Mao Zedong, „Analysis of the Classes in Chinese Society“,&nbsp;Selected Works of Mao Tse-tung: Vol. I, Foreign Languages Press, Peking, Čína.</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> Bilveer Singh,&nbsp;Quest for Political Power: <em>Communist Subversion and Militancy in Singapore</em>&nbsp;(Marshall Cavendish International (Asia) Pte Ltd, 2015).</p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> Leonard Patterson, „I Trained in Moscow for Black</p>
        <p>Revolution“,&nbsp;<a href="https://www.youtube.com/watch?v=GuXQjk4zhZs&amp;amp;t=1668s.">https://www.youtube.com/watch?v=GuXQjk4zhZs&amp;amp;t=1668s.</a></p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> William F. Jasper, „Anarchy in Los Angeles: Who Fanned the Flames, and Why?“&nbsp;The New American, 15.&nbsp;júla 1992,&nbsp;</p>
        <p><a href="https://www.thenewamerican.com/usnews/crime/item/15807-anarchy-in-los-angeles-who-fanned-the-flames-and-why"></a><a href="https://www.thenewamerican.com/usnews/crime/item/15807-anarchy-in-los-angeles-who-fanned-the-flames-and-why">https://www.thenewamerican.com/usnews/crime/item/15807-anarchy-in-los-angeles-who-fanned-the-flames-and-why</a>.</p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> Na tom istom mieste.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> Kongres USA, Biely dom, Výbor pro neamerickú činnosť,&nbsp;<em>Soviet Total War, ,Historic Mission’ of Violence and Deceit</em>&nbsp;(Washington, D.C.: US Government Printing Office, 1956)</p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> Marx and Engels, „Manifesto“</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> Vladimir Lenin, The State and Revolution (1918), 381–492, Marxists Internet Archive, zo 17. apríla 2020, <a href="https://www.marxists.org/archive/lenin/works/1917/staterev/ch01.htm.">https://www.marxists.org/archive/lenin/works/1917/staterev/ch01.htm.</a></p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Alexis de Tocqueville, Democracy in America, vol. 2, Henry Reeve, prel., (London: Longmans, Green, and Co., 1889), 289.</p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> Albert Venn Dicey, Lectures on the Relation Between Law &amp; Public Opinion in England, During the Nineteenth Century (London: Macmillan and Co., 1919), xxxviii, Online Library of Liberty, zo 17. apríla 2020, <a href="http://oll.libertyfund.org/pages/dicey-on-the-rise-of-legal-collectivism-in-the-20thc.">http://oll.libertyfund.org/pages/dicey-on-the-rise-of-legal-collectivism-in-the-20thc.</a></p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> Paul B. Skousen, The Naked Socialist (Salt Lake City, UT: Izzard Ink, 2014), Kindle edition.</p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> Peter Vinthagen Simpson, „Jonas, 32, Sewed Up His Own Leg after ER Wait,“ The Local, 3. augusta 2010, <a href="https://www.thelocal.se/20100803/28150.">https://www.thelocal.se/20100803/28150.</a></p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Juana Summers, „AP-NORC Poll: Most Americans See a Sharply Divided Nation,“ The Associated Press, <a href="http://www.apnorc.org/news-media/Pages/AP-NORC-Poll-Most-Americans-see-a-sharply-divided-nation.aspx.">http://www.apnorc.org/news-media/Pages/AP-NORC-Poll-Most-Americans-see-a-sharply-divided-nation.aspx.</a></p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> Ronald Reagan, „First Inaugural Address of Ronald Reagan“ (speech, US Capitol, Washington, DC, January 20, 1981), The Avalon Project, zo 17. apríla 2020, <a href="https://avalon.law.yale.edu/20th_century/reagan1.asp.">https://avalon.law.yale.edu/20th_century/reagan1.asp.</a></p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Donald Trump, „Remarks by President Trump at the 2017 Values Voter Summit“ (speech, Omni Shoreham Hotel, Washington, DC, October 13, 2017), White House, accessed April 17, 2020, <a href="https://www.whitehouse.gov/briefings-statements/remarks-president-trump-2017-values-voter-summit.">https://www.whitehouse.gov/briefings-statements/remarks-president-trump-2017-values-voter-summit.</a></p>
      <p>&nbsp;</p>
      <Link to={`/kapitola_09`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


